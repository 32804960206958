@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Helvetica";
  src: url("../public/fonts/Helvetica-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
}